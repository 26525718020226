.spacer {
    border: 2px solid var(--spotify-text-color);
    border-radius: 10px;
    padding: 1em 1em 1em 1em;
    background-color: rgba(1, 1, 1, 0.15);
    margin: 0.5em 0.5em 0.5em 0.5em;
    height: 95%;
}

.btn, .form-control {
    margin-top: 2px;
    margin-bottom: 2px;
}

.demobutton {
    text-decoration: underline;
    cursor: pointer;
}

.demobutton:hover {
    color: lightgrey;
}

.db-disabled {
    cursor: default;
    opacity: 50%;
}

.db-disabled:hover {
    color: var(--spotify-text-color);
}