:root {
  --spotify-text-color: #FAFAFA;
  --spotify-shadow-color: #424242;
  --spotify-accent-color: #1DB954;
}

body {
  background-color: var(--spotify-accent-color);
  background-image: url("./background-tile.png");
  font-family: Lato, sans-serif;
  color: var(--spotify-text-color);
  text-shadow: 2px 1px 1px var(--spotify-shadow-color);
}

.navbar {
  background-color: transparent !important;
  border-bottom: 1px solid var(--spotify-text-color);
  margin-bottom: 1vh;
  text-shadow: none;
}

.accordion-button:not(.collapsed), .accordion-button:not(.collapsed):after, .accordion-button:after,
.accordion-button.collapsed, .accordion-item, .accordion-button:focus, .accordion, .accordion-item, .accordion-button {
  color: white;
  background-color: transparent;
  box-shadow: none;
  text-shadow: 2px 1px 1px var(--spotify-shadow-color);
  border-color: white;
}

.accordion-button:not(.collapsed):after, .accordion-button:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.accordion-header {
  margin-top: 0;
}

.arrow {
  margin: 1em 1em 1em 1em;
  pointer-events: auto;
}

.footroom {
  height: 15vh;
}

a {
  color: var(--spotify-text-color);
}

a:hover {
  collapse: var(--spotify-accent-color);
}

.navbar .bg-success {
  background-color: var(--spotify-accent-color) !important;
  border-radius: 30px;
  margin-right: 3px;
  color: var(--spotify-text-color);
  text-shadow: 2px 1px 1px var(--spotify-shadow-color);
}

h1 {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

h2 {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.info-text {
  font-size: 13pt;
}

.progress-bar .bg-success {
  border-radius: inherit;
  margin-right: inherit;
}

.alert-danger {
  margin-top: 1em;
  text-shadow: none;
}

.btn-success {
  background-color: var(--spotify-accent-color) !important;
  border-color: var(--spotify-accent-color);
  text-shadow: 2px 1px 1px var(--spotify-shadow-color);
}

.welcome-area .bg-success {
  background-color: var(--spotify-accent-color) !important;
  border-radius: 30px;
  margin-right: 3px;
}

.progress-bar {
  background-color: var(--spotify-accent-color) !important;
}

.table {
  color: var(--spotify-text-color);
  border-top: 1px solid var(--spotify-text-color);
}

.table>:not(:first-child) {
  border-top: 1px solid var(--spotify-text-color);
}

.chartarea {
  text-shadow: none !important;
}

.loader h5 {
  margin-left: 4px;
}

.fixed-bottom {
  pointer-events: none; 
}