.welcome-area {
    margin-bottom: 10vh;
}

.welcome-area hr {
    border-top: 2px solid var(--spotify-text-color);
    opacity: 100%;
}

.spacer {
    border: 2px solid var(--spotify-text-color);
    border-radius: 10px;
    padding: 1em 1em 1em 1em;
    background-color: rgba(1, 1, 1, 0.15);
    margin: 0.5em 0.5em 0.5em 0.5em;
    height: 95%;
}

.disclaimer {
    display: flex;
    flex-direction: column;
}

.disclaimer p {
    text-align: center !important;
}